import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Store from '../../Common/Store';
import './../../App.css';

class HtmlEditor extends React.Component
{    
    render()
    {
        let content = this.props.content;
		let field_name = this.props.field_name;
        console.log("content++", content)
        return (
            <div>
                <CKEditor
                    editor={ ClassicEditor }
                    data={content}
                    config={{ link: {defaultProtocol: 'http://', addTargetToExternalLinks: true}, removePlugins: ['Table', 'BlockQuote', 'ImageUpload', 'Indent', 'MediaEmbed'] }}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const linkCommand = editor.commands.get( 'link' );
                        const model = editor.model;
                        const selection = model.document.selection;
                        const linkHref = selection.getAttribute( 'linkHref' );
                        if(linkHref !== undefined){
                            if (linkHref.indexOf("http://") == 0 || linkHref.indexOf("https://") == 0) {
                                console.log("The link has http or https.");
                            }else{
                                alert("Please ensure that you have included the full URL. It should begin with https:// or similar.")
                                return false
                            }
                        }

                        const data = editor.getData();
                        //console.log("data:", data);
						//if(field_name === 'testing_activity_undertaken'){
						//	Store.updateStore('testing_activity_undertaken', data);
						//}
						switch(field_name) {
							case 'meetingagenda':      
								Store.updateStore('meetingagenda', data);
								break;
							case 'minutes':      
								Store.updateStore('minutes', data);
								break;
							case 'actionDescription':      
								Store.updateStore('actionDescription', data);
								break;
						}
                        
                    } }
                    onBlur={ ( event, editor ) => {
                        // console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        // console.log( 'Focus.', editor );
                    } }
                />
            </div>
        );
    }
}
export default HtmlEditor;