import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import APICall from '../../Common/APICall.js';
import { MdClose } from 'react-icons/md';
import { Container, Row, Col } from 'react-grid-system';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { FaEye } from "react-icons/fa";
import { FaPen } from "react-icons/fa";
import Dropzone from '../Common/Dropzone.js';
import '../Common/Dropzone.css';
import moment from 'moment';
import { saveAs } from 'file-saver';
import AddCommitteeMembers from './AddCommitteeMembers';
import AddThirdPartyMembers from './AddThirdPartyMembers';
import CSLTable from '../Common/CSLTable';
import * as Style from '../Common/StyledComponents';
import CSLLoader from '../Common/CSLLoader';
import CSLLoaderNonPromise from '../Common/CSLLoaderNonPromise';
import CommitteeUtils from '../Common/CommitteeUtils';
import AlertBox from '../Common/AlertBox.js';

const ReactModalHeader = styled.div`
    padding: 10px 15px;
    background: linear-gradient(to bottom, #04ADA8 0%, #04ADA8 100%);
    color:#fff;
    border-radius: 4px 4px 0 0;
`;
const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 40%;
    height: 690px;
    background: #ffffff;
    border: 1px solid #868484;
    z-index: 1001;
    position: absolute;
    margin: 0 20%;
    border-radius: 6px;
`;


const CloseDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
`;
const ReactFormGroup = styled.div`
    margin-bottom: 15px;
    width: 98%;
`;
const UploadReferenceFileButton = styled.button`
    background-color: #04ADA8;
    color: #ffffff; 
    border-radius: 5px;
    border: 1px solid #04ADA8;
    padding: 3px 18px;
    background-image: linear-gradient(to bottom, #04ADA8 50%, #04ADA8);
`;
const IsActiveYesButton = styled.button`
    /*background-color: #115d9c;*/
    /*color: #ffffff;*/
    /*border-radius: 5px;*/
    border: 1px solid #04ADA8;
    padding: 3px 18px;
    /*background-image: linear-gradient(to bottom, #3e7bb4 50%, #115d9c);*/
`;
const IsActiveNoButton = styled.button`
    /*background-color: #115d9c;*/
    /*color: #ffffff; */
    /*border-radius: 5px;*/
    border: 1px solid #04ADA8;
    padding: 3px 18px;
    /*background-image: linear-gradient(to bottom, #3e7bb4 50%, #115d9c);*/
`;
const UploadReferenceFile = styled.div`
    padding: 10px;
    background: #f9f9f9;
    margin: 13px 0;
    color: black;
	marginBottom: 17px;
`;
const FormSubmitButton = styled.button`
    color: #fff;
    background: linear-gradient(to top, #04ADA8 0%, #04ADA8 100%);
    box-shadow: inset 0px 1px 1px 0px #04ADA8;
    width: 116px;
    padding: 6px 16px;
    border: 1px solid #04ADA8;
    border-radius:3px;
    margin-right:10px;
`;
const FormCancelButton = styled.button`
    color: #000000;
    background: linear-gradient(to top, #FFFFFF 0%, #FFFFFF 100%);
    box-shadow: inset 0px 1px 1px 0px #FFFFFF;
    width: 116px;
    padding: 6px 16px;
    border: 1px solid grey;
    border-radius:3px;
    margin-right:10px;
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 110px;
    font-size: 11px;
    height: 25px;
    border-radius: 4px;
    margin-right:2px;
`;

/*  */
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 80px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 94%;
    position: absolute;
    top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 50px;
`;



class NewCommitteeModal extends React.Component {
    constructor(props) {
        super(props);
        this.inputref = React.createRef();
        this.state = {
            editMode: false,
            association: 'parent',
            gc_id: '0',
            grouping_id: '0',
            grouping_value_id: '0',
            sel_grouping: '0',
            committeeMembersPlusIconShow: true,
            committeename: "",
            reportsto: "",
            isparentidcommittee: true,
            committeemembers: [],
            thirdpartyembers: [],
            purposeofcommitee: "",
            termrefrencefile: "",
            meetingfrequency: 0,
            memberList: [],
            committeeroles: [],
            selectedmember: [],
            selectedrole: [],
            curBinFiles: [],
            committeelist: [],
            selectedFile: [],
            fileRows: [],
            perposeofcommitee: "",
            isactive: true,
            is_loader: false,
            isactiveyesbgcolor: "#115d9c",
            isactivenobgcolor: "#ffffff",
            isactiveyesfontcolor: "#ffffff",
            isactivenofontcolor: "#000000",
            ReferenceFiles: [],
            ready: true,
            alert_param: null,
            purpose_chars: 250,
            fontfamily: "Helvetica"

        }



    }

    toggleIsActiveYesButton = (e) => {
        e.preventDefault();
        //console.log("btntype=>"+btntype);
        if (this.state.isactiveyesbgcolor === "#ffffff") {
            this.setState({
                isactiveyesbgcolor: "#04ADA8",
                isactivenobgcolor: "#ffffff",
                isactiveyesfontcolor: "#ffffff",
                isactivenofontcolor: "#000000",
                isactive: true
            });
        }
    }
    toggleIsActiveNoButton = (e) => {
        e.preventDefault();
        //console.log("btntype=>"+btntype);
        if (this.state.isactivenobgcolor === "#ffffff") {
            this.setState({
                isactiveyesbgcolor: "#ffffff",
                isactivenobgcolor: "#04ADA8",
                isactiveyesfontcolor: "#000000",
                isactivenofontcolor: "#ffffff",
                isactive: false
            });
        }
    }

    handleFileSelect = (e) => {
        e.preventDefault();
        //this.fileSelector.click();
        this.inputref.current.click();
    }

   filesLoaded = (jsonObj) => {
        let me = Store.getStoreData('loggedin_user_info');
		let files_to_show = JSON.parse(JSON.stringify(jsonObj.files));
		let curBinFiles = this.state.curBinFiles;
		let ReferenceFiles = this.state.ReferenceFiles;

		console.log("files_to_show,",files_to_show);
		//console.log("files_to_show,",parseInt(files_to_show[0].uid));
		console.log("curBinFiles,",curBinFiles);
		console.log("curBinFiles,",jsonObj);
		//return;

		let file_exists = {};
		let file_elements = [];
		let file_names = [];

        for (let p of ReferenceFiles) {
            file_exists[p.file_name] = true;
        }

        if(jsonObj.command === 'add'){
			for(let i=0; i < files_to_show.length; i++) {
				if(typeof files_to_show[i].name !== "undefined"  ){
					files_to_show[i].name = jsonObj.files[i].name.replace('&', '');
					if(!('user_id' in files_to_show[i])) {
						files_to_show[i].user_id = me.ID;
					}
					if(typeof file_exists[files_to_show[i].name] === "undefined"){
                        let fElement = {name: files_to_show[i].name, bin_file: files_to_show[i].bin_file.replace(/\n/g,'')};
                        file_elements.push(fElement);
						file_exists[files_to_show[i].name] = true;
						file_names.push({name : files_to_show[i].name , uid: files_to_show[i].uid});

					}
				}

			}
			console.log("file_names,",file_names);
			let postData = { command: "uploadFiles", data: { fElement : file_elements }};
			let api = new APICall();
			let that = this;
			api.command(postData, function callback(result){that.processFilesLoaded(file_names,result)});
		}else{
			for(let i=0; i < curBinFiles.length; i++) {
				if(curBinFiles[i].uid.toString() === jsonObj.deleted_uid.toString()){
					curBinFiles.splice(i,1);
				}
			}
			for(let i=0; i < ReferenceFiles.length; i++) {
				if(ReferenceFiles[i].file_id.toString() === jsonObj.deleted_uid.toString()){
					ReferenceFiles.splice(i,1);
				}
			}
			this.setState({curBinFiles,ReferenceFiles});
		}

    }
	processFilesLoaded = (file_names,result) => {
       console.log("processFilesLoaded result,",result,file_names);

	   let file_ids = result;
	   let curBinFiles = this.state.curBinFiles;
	   let ReferenceFiles = this.state.ReferenceFiles;
	   for(let i=0;i<file_ids.length;i++){
		   ReferenceFiles.push({file_name : file_names[i].name , file_id : file_ids[i].file_id});
		   curBinFiles.push({name : file_names[i].name , uid : file_names[i].uid});
	   }
console.log("processFilesLoaded result,",curBinFiles);
	   let curBinFiles_1 = curBinFiles.filter((curBinFiles, index, self) => index === self.findIndex((t) => (t.name === curBinFiles.name && t.uid === curBinFiles.uid)));
       this.setState({
			curBinFiles: curBinFiles_1,
			ReferenceFiles: ReferenceFiles,
		});
	}
    filesRemoved = (jsonObj) => {
        //if(jsonObj.files_to_show_size === 0){
        //	this.setState({curBinFiles: []});
        //}
    }

    filesLoaded_old_done_by_aslam = (jsonObj) => {
        let contact_id = Store.getStoreData('contact_id');
        let files_to_show = JSON.parse(JSON.stringify(jsonObj.files));
        let binFiles = this.state.binFiles;

        console.log("binFiles", binFiles)
        console.log("jsonObj", jsonObj)

        let file_exists = {};
        let curBinFiles = [];

        if (jsonObj.command === 'add') {
            for (let i = 0; i < files_to_show.length; i++) {
                if (typeof files_to_show[i].name !== "undefined") {
                    files_to_show[i].name = jsonObj.files[i].name.replace('&', '');
                    if (!('user_id' in files_to_show[i])) {
                        files_to_show[i].user_id = contact_id;
                        files_to_show[i].upload_date = Date.now();
                    }
                    if (typeof file_exists[files_to_show[i].name] === "undefined") {
                        binFiles.push(files_to_show[i]);
                        file_exists[files_to_show[i].name] = true;
                    }
                }

            }
            curBinFiles = binFiles.filter((binFiles, index, self) => index === self.findIndex((t) => (t.name === binFiles.name && t.uid === binFiles.uid)));
            this.setState({ binFiles: curBinFiles });
        } else {
            for (let i = 0; i < binFiles.length; i++) {
                if (binFiles[i].uid.toString() === jsonObj.uid.toString()) {
                    binFiles.splice(i, 1);
                }
            }
            this.setState({ binFiles: binFiles });
        }
    }


    handleInputChange = event => {
        //Disabled as prevents checkboxes being clicked
        event.preventDefault();

        let purpose_chars = this.state.purpose_chars;
        if (event.target.name === "perposeofcommitee") purpose_chars = 250 - event.target.value.length;

        this.setState({ [event.target.name]: event.target.value, purpose_chars });

    };

    openConfirmationBoxModal = (event) => {
        event.preventDefault();
        //Store.updateStore('ConfirmationBoxOverlay', `${document.body.scrollHeight.toString()}px`);
        Store.updateStore('modal_committee_Edit_CommitteeID', 0);
        Store.updateStore('ConfirmationBoxVisibility', true);
    }

    /*closeModal = () => {
        Store.updateStore('OverlayHeight', null);
        Store.updateStore('modal_committee_Edit', false);
        Store.updateStore('modal_overlay_committee_Edit', null);
        Store.updateStore('modal_committee_Edit_CommitteeID', 0)
    }*/

    closeModal = (event) => {
        event.preventDefault();
        Store.updateStore('OverlayHeight', null);
        Store.updateStore('modal_committee_Edit', false);
        Store.updateStore('modal_overlay_committee_Edit', null);
        Store.updateStore('modal_committee_Edit_CommitteeID', null)
        this.props.closeModal();
    }

    refreshPage = () => {
        window.location.reload();
    }
    deleteAttachment = (arrayindex) => {
        //console.log("arrayindex==>"+arrayindex);
        let selectedFile = this.state.selectedFile;
        let fileJsonObj = selectedFile[arrayindex];
        let file_id = fileJsonObj.file_id;
        const url = Store.getStoreData('api_url');
        let postData = { command: "deleteFile", data: {  file_id: file_id } };

        let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData) };
        fetch(url, httpVerb)
            .then((res) => res.json())
            .then(result => {
                //console.log("file_name=>"+file_name);
                //console.log("MM=>"+JSON.stringify(result.base64filedata.bin_file));
                //that.downloadFileToBrowser(result.base64filedata.bin_file,result.base64filedata.name);
                if (result.error_code == 0) {
                    selectedFile.splice(arrayindex, 1);
                    this.setState({
                        selectedFile: selectedFile

                    }, () => {

                        this.createFileRows();
                    });
                }

            });

    }
    downloadFileToBrowser = (bin_file, fileName) => {
        let dnld = bin_file.replace(/ /g, '+');
        // console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        // console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, fileName);
    }
    downloadAttachment = (file_id, file_name) => {
        //console.log("arrayindex==>"+arrayindex);
        console.log("file_id==>" + file_id);
        //console.log("file_name==>"+file_name);
        //this.state.selectedFile[i].file_id,this.state.selectedFile[i].file_name
        let that = this;

        const url = Store.getStoreData('api_url');
        let postData = { command: "downloadFile", data: {  file_id: file_id } };

        let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData) };
        fetch(url, httpVerb)
            .then((res) => res.json())
            .then(result => {
                //console.log("file_name=>"+file_name);
                //console.log("MM=>"+JSON.stringify(result.base64filedata.bin_file));
                that.downloadFileToBrowser(result.base64filedata.bin_file, result.base64filedata.name);

            });
    }
    createFileRows = () => {
        let table = [];
        let curBinFiles = [];
        let that = this;
        //let children = [];
        for (let i = 0; i < this.state.selectedFile.length; i++) {
            let children = [];
            children.push(<div style={{ float: "left", width: "300px" }}><p><a onClick={() => that.downloadAttachment(that.state.selectedFile[i].file_id, that.state.selectedFile[i].file_name)} style={{ cursor: 'pointer' }}>{this.state.selectedFile[i].file_name}</a></p></div>);
            children.push(<div style={{ float: "right" }}><MdClose style={{ color: "#979797", cursor: 'pointer' }} onClick={() => this.deleteAttachment(i)} /></div>);

            table.push(<div>{children}</div>);

            //curBinFiles.push({uid : that.state.selectedFile[i].file_id.toString() , name : that.state.selectedFile[i].file_name});
        }
        this.setState({
            fileRows: table,
            //curBinFiles: curBinFiles,
        });
        //return children;
    }

    checkContactRoleIsSet = () => {
		let contact_role_hash = Store.getStoreData('contact_role_hash');
		if(contact_role_hash === null)this.checkContactRoleIsSet();
		if(contact_role_hash !== null)this.processIndexData();
    }
    componentDidMount() {

        console.log("modal_committee_Details_CommitteeID,",Store.getStoreData('modal_committee_Details_CommitteeID'))
        this.setState({
            ready: false,
        });
        setTimeout(() => {
			this.processIndexData();
        }, 2000);
        /*let postData = { command: "getCommitteeAddEditData", data: {  employee_id: 0, committeeid: parseInt(Store.getStoreData('modal_committee_Edit_CommitteeID')) } };
        let api = new APICall();
        api.command(postData, this.processIndexData);*/
		//this.processIndexData();
    }

    processIndexData = () => {
		let committeeUtils = new CommitteeUtils();
		let employee_list = Store.getStoreData('employee_list');
		let module_configs = Store.getStoreData('module_configs');
		let committee_with_count = Store.getStoreData('committee_with_count');
		let gov_committees = Store.getStoreData('gov_committees');
		let contact_role_hash = Store.getStoreData('contact_role_hash');
		console.log("processIndexData,",Store.getStoreData('contact_role_hash'),committee_with_count,employee_list);
        let committeename = "";
        let perposeofcommitee = "";
        let meetingfrequency = 0;
        let reportsto = "";
        let committeemembers = [];
        let memberList = [];
        let committeeroles = [];
        let thirdpartyembers = [];
        let committeelist = [];
        let selectedmember = [];
        let selectedFile = [];
		let committeeroleList = [];

		let committeeroleList_Temp = module_configs.committee_roles;
		for(let item of committeeroleList_Temp) {
			if(item.status.toUpperCase() === 'ACTIVE'){
				committeeroleList.push(item);
			}
		}

        employee_list.map(v => {
            v['selected'] = false
        });

        memberList = employee_list;

        let temp_memberList = [];
        console.log("contact_role_hash=>",contact_role_hash);
        for (let i = 0; i < memberList.length; i++) {
            if (contact_role_hash !== null && memberList[i].contact_id in contact_role_hash && contact_role_hash[memberList[i].contact_id] !== 'no_access') {
                temp_memberList.push(memberList[i]);
            }
        }
        memberList = temp_memberList;
        //return result;
        committeeroleList.map(v => {
            v['selected'] = false
        });

        let tempcm = [];
        for (let i = 0; i < 3; i++) {

            tempcm.push({
                id: i + 1,
                members: memberList,
                roles: committeeroleList
            })
        }

        committeemembers = tempcm;
        committeeroles = committeeroleList;
        committeeroles.sort(Utils.compare);

        let tmparr = this.state.thirdpartyembers;
        if (tmparr.length == 0) {
            let employeeid = Utils.genKey(10);
            tmparr.push({
                employeeid: employeeid,
                thirdpartyembername: "",
                thirdpartyembercompany: "",
                thirdpartyemberreason: ""
            });
        }

        thirdpartyembers = tmparr;

        //committeelist = result.getCommitteeWithCount.committees;
        committeelist = committee_with_count.committees;
        let assignments = Store.getStoreData('assignments');
        let tag_values = Store.getStoreData('tag_values');
        let companies_assoc = Store.getStoreData('companies_assoc');
        let employee_assoc = Store.getStoreData('employee_assoc');
        let filtered_committees = [];
        //console.log('companies_assoc', companies_assoc);
        //console.log('tag_values', tag_values);
        let parent_company_name = Store.getStoreData('company_name');
		let current_committee_unparsed = null;

        for (let gc of committeelist) {
			gc.IsActive = (gc.IsActive.toString() === '1') ? true : false;
			if(typeof Store.getStoreData('modal_committee_Edit_CommitteeID') !== 'undefined' && parseInt(Store.getStoreData('modal_committee_Edit_CommitteeID')) > 0 && parseInt(Store.getStoreData('modal_committee_Edit_CommitteeID')) === parseInt(gc.PK_CommitteeID))current_committee_unparsed = gc;
			if (typeof Store.getStoreData('modal_committee_Edit_CommitteeID') !== 'undefined' && parseInt(Store.getStoreData('modal_committee_Edit_CommitteeID')) > 0 && parseInt(Store.getStoreData('modal_committee_Edit_CommitteeID')) === parseInt(gc.PK_CommitteeID))continue;
            //console.log('gc', gc)
            gc.json_data = JSON.parse(gc.Json_Data)
            let association = 'association' in gc.json_data ? gc.json_data.association : 'parent';
            switch (association) {
                case 'gc': if (gc.json_data.gc_id in companies_assoc) {
                    gc.name = companies_assoc[gc.json_data.gc_id].company_name + '-' + gc.json_data.committeename;
                    gc.display = `${gc.json_data.committeename} (${companies_assoc[gc.json_data.gc_id].company_name})`
                    filtered_committees.push(gc);
                }
                    break;
                case 'grouping': if (gc.json_data.grouping_id in assignments.tags && gc.json_data.grouping_value_id in assignments.tags[gc.json_data.grouping_id]) {
                    gc.name = `${tag_values[gc.json_data.grouping_id].name} - ${tag_values[gc.json_data.grouping_id].values[gc.json_data.grouping_value_id]} - ${gc.json_data.committeename}`;
                    gc.display = `${gc.json_data.committeename} (${tag_values[gc.json_data.grouping_id].name} - ${tag_values[gc.json_data.grouping_id].values[gc.json_data.grouping_value_id]})`
                    filtered_committees.push(gc);
                }
                    break;
                default: gc.name = `${parent_company_name} - ${gc.json_data.committeename}`;
                    gc.display = `${gc.json_data.committeename} (${parent_company_name})`
                    filtered_committees.push(gc);
            }
        }
		if(current_committee_unparsed !== null){
			//console.log("current_committee_unparsed,",current_committee_unparsed,gov_committees);
			let current_committee_obj_with_tree = committeeUtils.getCommitteeObjectFromRecursiveList(gov_committees.children,current_committee_unparsed);
			//console.log("current_committee_unparsed,",current_committee_obj_with_tree);
			if(current_committee_obj_with_tree !== null){
				//console.log("current_committee_unparsed,",current_committee_obj_with_tree);
				let recursive_children_c_assoc = committeeUtils.getRecursiveCommitteesAssocUnderCurrentCommittee(current_committee_obj_with_tree);
				//console.log("current_committee_unparsed,",recursive_children_c_assoc,filtered_committees);
				if(Object.keys(recursive_children_c_assoc).length > 0){
					let u_filtered_committees = [];
					for(let fc of filtered_committees){
						if(!(fc.PK_CommitteeID.toString() in recursive_children_c_assoc))u_filtered_committees.push(fc)
					}
					filtered_committees = u_filtered_committees;
				}
			}
		}


        filtered_committees.sort(this.nameCompare);
        //console.log('filtered_committees', filtered_committees)

        if ('undefined' != typeof Store.getStoreData('modal_committee_Edit_CommitteeID') && parseInt(Store.getStoreData('modal_committee_Edit_CommitteeID')) > 0) {

            //let parsedresult = JSON.parse(result.getCommitteeByID.Json_Data);
            let parsedresult = JSON.parse(current_committee_unparsed.Json_Data);
            Store.updateStore('committeeData', parsedresult);
            //let tmpcommitteemembers = [];
            let tmpcommitteemembers = JSON.parse(JSON.stringify(committeemembers));
			console.log("tmpcommitteemembers,",tmpcommitteemembers);
			for(let t of tmpcommitteemembers){
				for(let m of t.members){
					m.is_active=('is_active' in m) ? m.is_active : true;
					if(!employee_assoc[m.employeeid.toString()].is_active)m.is_active=false;
				}
				//tmpcommitteemembers.push(t);
			}
			console.log("tmpcommitteemembers,",tmpcommitteemembers);

            let new_tmpcommitteemembers = [];
            if (typeof tmpcommitteemembers != 'undefined' && tmpcommitteemembers.length > 0) {
                if (parsedresult.committeemembers.length > tmpcommitteemembers.length) {
                    let mismatchcount = (parsedresult.committeemembers.length - tmpcommitteemembers.length)
                    for (var i = 0; i <= mismatchcount - 1; i++) {
                        tmpcommitteemembers.push(JSON.parse(JSON.stringify(tmpcommitteemembers[0])))
                        tmpcommitteemembers[tmpcommitteemembers.length - 1].id = tmpcommitteemembers.length
                    }

                }

                //console.log("tmpcommitteemembers 1 ==>"+JSON.stringify(tmpcommitteemembers));

                let employee_hash = {};
                for (let i = 0; i < parsedresult.committeemembers.length; i++) {
                    let str = "key_" + i + "_" + parsedresult.committeemembers[i].employeeid;
                    employee_hash[str] = true;
                }

                for (let i = 0; i < tmpcommitteemembers.length; i++) {
                    //new_tmpcommitteemembers.members = [];
                    let members = tmpcommitteemembers[i].members;
                    let newmembers = [];
                    for (let j = 0; j < members.length; j++) {
                        let str = "key_" + i + "_" + members[j].employeeid;
                        let localmember = members[j];
                        if (typeof employee_hash[str] !== 'undefined' && employee_hash[str] === true) {
                            //console.log("in true==>"+localmember.employeeid+",employee name==>"+localmember.employeename);
                            localmember.selected = true;
                            tmpcommitteemembers[i]['selectedemp'] = members[j].employeeid;
                        } else {
                            localmember.selected = false;
                        }
                        newmembers.push(localmember);
                    }
                    members = [];
                    tmpcommitteemembers[i].members = [];
                    let newarr = tmpcommitteemembers[i];
                    new_tmpcommitteemembers[i] = newarr;
                    new_tmpcommitteemembers[i].members = newmembers;
                }

                let role_hash = {};
                for (let i = 0; i < parsedresult.committeemembers.length; i++) {
                    let str = "key_" + i + "_" + parsedresult.committeemembers[i].employeeid + "_" + parsedresult.committeemembers[i].committeeroleid;
                    role_hash[str] = true;
                }

                //console.log("tmpcommitteemembers 2 ==>"+JSON.stringify(new_tmpcommitteemembers));
                let roles = [];

                new_tmpcommitteemembers.forEach(function (c, i) {
                    var roleselected = false;
                    console.log("i==>" + i);
                    c.roles.forEach(function (r, ri) {
                        parsedresult.committeemembers.forEach(function (pc, pci) {
                            if (roleselected == false) {
                                let str = "key_" + i + "_" + pc.employeeid + "_" + r.committeeroleid;
                                //if (role_hash[str]) {
                                if (r.committeeroleid === pc.committeeroleid && role_hash[str]) {
                                    //if (r.committeeroleid === pc.committeeroleid && roles.indexOf(r.committeeroleid) === -1) {
                                    //if (r.committeeroleid === pc.committeeroleid  && !role_hash[str]) {
                                    roleselected = true;
                                    roles.push(pc.committeeroleid);
                                    //roles.push(r.committeeroleid);
                                    r['selected'] = true;
                                    c['selectedrole'] = pc.committeeroleid;

                                    //role_hash[str] = true;
                                } else {
                                    r['selected'] = false;
                                }

                            }
                        })
                    })
                })
            }

            let isactiveyesbgcolor = "";
            let isactivenobgcolor = "";
            let isactiveyesfontcolor = "";
            let isactivenofontcolor = "";
            //console.log("parsedresult.IsActive==>"+result.getCommitteeByID.IsActive);
            if (current_committee_unparsed.IsActive) {
                isactiveyesbgcolor = "#04ADA8";
                isactivenobgcolor = "#ffffff";
                isactiveyesfontcolor = "#ffffff";
                isactivenofontcolor = "#000000";
            } else {
                isactiveyesbgcolor = "#ffffff";
                isactivenobgcolor = "#04ADA8";
                isactiveyesfontcolor = "#000000";
                isactivenofontcolor = "#ffffff";
            }
            console.log("parsedresult =>", parsedresult);
            let curBinFiles = [];
            let reference_files = [];
            if ('ReferenceFiles' in parsedresult) {
                reference_files = parsedresult.ReferenceFiles;
                parsedresult.ReferenceFiles.forEach((item) => {
                    curBinFiles.push({ uid: item.file_id, name: item.file_name });
                });
            }


            let purpose_chars = 250 - parsedresult.perposeofcommitee.length;
            let association = ('association' in parsedresult) ? parsedresult.association : this.state.association;
            let gc_id = ('gc_id' in parsedresult) ? parsedresult.gc_id : this.state.gc_id;
            let sel_grouping = ('association' in parsedresult && parsedresult.association === 'grouping') ? parsedresult.grouping_id + '-' + parsedresult.grouping_value_id : this.state.sel_grouping;


            this.setState({
                ready: true,
                committeename: parsedresult.committeename,
                perposeofcommitee: parsedresult.perposeofcommitee,
                meetingfrequency: parsedresult.meetingfrequency,
                reportsto: parsedresult.reportsto,
                selectedmember: parsedresult.committeemembers,
                committeemembers: new_tmpcommitteemembers,
                thirdpartyembers: parsedresult.thirdpartyembers,
                committeelist: filtered_committees,
                memberList: memberList,
                committeeroles: committeeroles,
                editMode: true,
                isactive: current_committee_unparsed.IsActive,
                isactiveyesbgcolor: isactiveyesbgcolor,
                isactivenobgcolor: isactivenobgcolor,
                isactiveyesfontcolor: isactiveyesfontcolor,
                isactivenofontcolor: isactivenofontcolor,
                selectedFile: parsedresult.ReferenceFiles,
                ReferenceFiles: reference_files,
                curBinFiles: curBinFiles,
                purpose_chars: purpose_chars,
                association: association,
                gc_id: gc_id,
                sel_grouping: sel_grouping,

            });
        } else {
            //console.log("in add");
			let company_users = Store.getStoreData('company_users');
			let nmemberList = memberList.filter(function (el) {
				return company_users[el.contact_id.toString()].IsActive===true && company_users[el.contact_id.toString()].IsDeleted===false;
			});
			for(let c of committeemembers){
				c.members = c.members.filter(function (el) {
					return company_users[el.contact_id.toString()].IsActive===true && company_users[el.contact_id.toString()].IsDeleted===false;
				});
			}
            this.setState({
                ready: true,
                selectedmember: selectedmember,
                committeelist: filtered_committees,
                memberList: nmemberList,
                committeeroles: committeeroles,
                committeemembers: committeemembers,
                thirdpartyembers: thirdpartyembers,
                selectedFile: selectedFile,

            });
        }
		this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }

    processIndexData_obsolate = (result) => {
		console.log("processIndexData,",result,Store.getStoreData('contact_role_hash'));
        let committeename = "";
        let perposeofcommitee = "";
        let meetingfrequency = 0;
        let reportsto = "";
        let committeemembers = [];
        let memberList = [];
        let committeeroles = [];
        let thirdpartyembers = [];
        let committeelist = [];
        let selectedmember = [];
        let selectedFile = [];

        result.merberList.map(v => {
            v['selected'] = false
        });

        memberList = result.merberList;
        let contact_role_hash = Store.getStoreData('contact_role_hash');
        let temp_memberList = [];
        console.log("contact_role_hash=>",contact_role_hash);
        for (let i = 0; i < memberList.length; i++) {
            if (memberList[i].contact_id in contact_role_hash && contact_role_hash[memberList[i].contact_id] !== 'no_access') {
                temp_memberList.push(memberList[i]);
            }
        }
        memberList = temp_memberList;
        //return result;
        result.committeeroleList.map(v => {
            v['selected'] = false
        });

        let tempcm = [];
        for (let i = 0; i < 3; i++) {

            tempcm.push({
                id: i + 1,
                members: memberList,
                roles: result.committeeroleList
            })
        }

        committeemembers = tempcm;
		//console.log("committeeroleList,",result.committeeroleList);
        committeeroles = result.committeeroleList;
        committeeroles.sort(Utils.compare);

        let tmparr = this.state.thirdpartyembers;
        if (tmparr.length == 0) {
            let employeeid = Utils.genKey(10);
            tmparr.push({
                employeeid: employeeid,
                thirdpartyembername: "",
                thirdpartyembercompany: "",
                thirdpartyemberreason: ""
            });
        }

        thirdpartyembers = tmparr;

        committeelist = result.getCommitteeWithCount.committees;
        let assignments = Store.getStoreData('assignments');
        let tag_values = Store.getStoreData('tag_values');
        let companies_assoc = Store.getStoreData('companies_assoc');
        let filtered_committees = [];
        //console.log('companies_assoc', companies_assoc);
        //console.log('tag_values', tag_values);
        let parent_company_name = Store.getStoreData('company_name');

        for (let gc of committeelist) {
			if (typeof Store.getStoreData('modal_committee_Edit_CommitteeID') !== 'undefined' && parseInt(Store.getStoreData('modal_committee_Edit_CommitteeID')) > 0 && parseInt(Store.getStoreData('modal_committee_Edit_CommitteeID')) === parseInt(gc.PK_CommitteeID))continue;
            //console.log('gc', gc)
            gc.json_data = JSON.parse(gc.Json_Data)
            let association = 'association' in gc.json_data ? gc.json_data.association : 'parent'
            switch (association) {
                case 'gc': if (gc.json_data.gc_id in companies_assoc) {
                    gc.name = companies_assoc[gc.json_data.gc_id].company_name + '-' + gc.json_data.committeename;
                    gc.display = `${gc.json_data.committeename} (${companies_assoc[gc.json_data.gc_id].company_name})`
                    filtered_committees.push(gc);
                }
                    break;
                case 'grouping': if (gc.json_data.grouping_id in assignments.tags && gc.json_data.grouping_value_id in assignments.tags[gc.json_data.grouping_id]) {
                    gc.name = `${tag_values[gc.json_data.grouping_id].name} - ${tag_values[gc.json_data.grouping_id].values[gc.json_data.grouping_value_id]} - ${gc.json_data.committeename}`;
                    gc.display = `${gc.json_data.committeename} (${tag_values[gc.json_data.grouping_id].name} - ${tag_values[gc.json_data.grouping_id].values[gc.json_data.grouping_value_id]})`
                    filtered_committees.push(gc);
                }
                    break;
                default: gc.name = `${parent_company_name} - ${gc.json_data.committeename}`;
                    gc.display = `${gc.json_data.committeename} (${parent_company_name})`
                    filtered_committees.push(gc);
            }
        }

        filtered_committees.sort(this.nameCompare);
        //console.log('filtered_committees', filtered_committees)

        if ('undefined' != typeof Store.getStoreData('modal_committee_Edit_CommitteeID') && parseInt(Store.getStoreData('modal_committee_Edit_CommitteeID')) > 0) {


            let parsedresult = JSON.parse(result.getCommitteeByID.Json_Data);

            //console.log("parsedresult", parsedresult);

            Store.updateStore('committeeData', parsedresult);
            //let tmpcommitteemembers = committeemembers;
            let tmpcommitteemembers = JSON.parse(JSON.stringify(committeemembers));

            let new_tmpcommitteemembers = [];
            if (typeof tmpcommitteemembers != 'undefined' && tmpcommitteemembers.length > 0) {
                if (parsedresult.committeemembers.length > tmpcommitteemembers.length) {
                    let mismatchcount = (parsedresult.committeemembers.length - tmpcommitteemembers.length)
                    for (var i = 0; i <= mismatchcount - 1; i++) {
                        tmpcommitteemembers.push(JSON.parse(JSON.stringify(tmpcommitteemembers[0])))
                        tmpcommitteemembers[tmpcommitteemembers.length - 1].id = tmpcommitteemembers.length
                    }

                }

                //console.log("tmpcommitteemembers 1 ==>"+JSON.stringify(tmpcommitteemembers));

                let employee_hash = {};
                for (let i = 0; i < parsedresult.committeemembers.length; i++) {
                    let str = "key_" + i + "_" + parsedresult.committeemembers[i].employeeid;
                    employee_hash[str] = true;
                }
                //console.log("employee_hash==>"+JSON.stringify(employee_hash));



                //new_tmpcommitteemembers.members = [];
                for (let i = 0; i < tmpcommitteemembers.length; i++) {
                    //new_tmpcommitteemembers.members = [];
                    let members = tmpcommitteemembers[i].members;
                    let newmembers = [];
                    for (let j = 0; j < members.length; j++) {
                        let str = "key_" + i + "_" + members[j].employeeid;
                        let localmember = members[j];
                        if (typeof employee_hash[str] !== 'undefined' && employee_hash[str] === true) {
                            //console.log("in true==>"+localmember.employeeid+",employee name==>"+localmember.employeename);
                            localmember.selected = true;
                            tmpcommitteemembers[i]['selectedemp'] = members[j].employeeid;
                        } else {
                            localmember.selected = false;
                        }
                        newmembers.push(localmember);
                    }
                    members = [];
                    tmpcommitteemembers[i].members = [];
                    let newarr = tmpcommitteemembers[i];

                    //newarr.members = [];
                    //newarr.members = newmembers;
                    //console.log("newarr ==>"+JSON.stringify(newarr));
                    new_tmpcommitteemembers[i] = newarr;
                    new_tmpcommitteemembers[i].members = newmembers;
                    //console.log("newarr ==>"+JSON.stringify(newarr));
                }

                let role_hash = {};
                for (let i = 0; i < parsedresult.committeemembers.length; i++) {
                    let str = "key_" + i + "_" + parsedresult.committeemembers[i].employeeid + "_" + parsedresult.committeemembers[i].committeeroleid;
                    role_hash[str] = true;
                }

                //console.log("tmpcommitteemembers 2 ==>"+JSON.stringify(new_tmpcommitteemembers));
                let roles = [];

                new_tmpcommitteemembers.forEach(function (c, i) {
                    var roleselected = false;
                    console.log("i==>" + i);
                    c.roles.forEach(function (r, ri) {
                        parsedresult.committeemembers.forEach(function (pc, pci) {
                            if (roleselected == false) {
                                let str = "key_" + i + "_" + pc.employeeid + "_" + r.committeeroleid;
                                //if (role_hash[str]) {
                                if (r.committeeroleid === pc.committeeroleid && role_hash[str]) {
                                    //if (r.committeeroleid === pc.committeeroleid && roles.indexOf(r.committeeroleid) === -1) {
                                    //if (r.committeeroleid === pc.committeeroleid  && !role_hash[str]) {
                                    roleselected = true;
                                    roles.push(pc.committeeroleid);
                                    //roles.push(r.committeeroleid);
                                    r['selected'] = true;
                                    c['selectedrole'] = pc.committeeroleid;

                                    //role_hash[str] = true;
                                } else {
                                    r['selected'] = false;
                                }

                            }
                        })
                    })
                })

                //console.log("new_tmpcommitteemembers 3==> "+JSON.stringify(new_tmpcommitteemembers));
                //console.log("role_hash==>"+JSON.stringify(role_hash));
                //console.log("tmpcommitteemembers==>"+JSON.stringify(tmpcommitteemembers));
            }

            let isactiveyesbgcolor = "";
            let isactivenobgcolor = "";
            let isactiveyesfontcolor = "";
            let isactivenofontcolor = "";
            //console.log("parsedresult.IsActive==>"+result.getCommitteeByID.IsActive);
            if (result.getCommitteeByID.IsActive) {
                isactiveyesbgcolor = "#04ADA8";
                isactivenobgcolor = "#ffffff";
                isactiveyesfontcolor = "#ffffff";
                isactivenofontcolor = "#000000";
            } else {
                isactiveyesbgcolor = "#ffffff";
                isactivenobgcolor = "#04ADA8";
                isactiveyesfontcolor = "#000000";
                isactivenofontcolor = "#ffffff";
            }
            console.log("parsedresult =>", parsedresult);
            let curBinFiles = [];
            let reference_files = [];
            if ('ReferenceFiles' in parsedresult) {
                reference_files = parsedresult.ReferenceFiles;
                parsedresult.ReferenceFiles.forEach((item) => {
                    curBinFiles.push({ uid: item.file_id, name: item.file_name });
                });
            }


            let purpose_chars = 250 - parsedresult.perposeofcommitee.length;
            let association = ('association' in parsedresult) ? parsedresult.association : this.state.association;
            let gc_id = ('gc_id' in parsedresult) ? parsedresult.gc_id : this.state.gc_id;
            let sel_grouping = ('association' in parsedresult && parsedresult.association === 'grouping') ? parsedresult.grouping_id + '-' + parsedresult.grouping_value_id : this.state.sel_grouping;


            this.setState({
                ready: true,
                committeename: parsedresult.committeename,
                perposeofcommitee: parsedresult.perposeofcommitee,
                meetingfrequency: parsedresult.meetingfrequency,
                reportsto: parsedresult.reportsto,
                selectedmember: parsedresult.committeemembers,
                committeemembers: new_tmpcommitteemembers,
                thirdpartyembers: parsedresult.thirdpartyembers,
                committeelist: filtered_committees,
                memberList: memberList,
                committeeroles: committeeroles,
                editMode: true,
                isactive: result.getCommitteeByID.IsActive,
                isactiveyesbgcolor: isactiveyesbgcolor,
                isactivenobgcolor: isactivenobgcolor,
                isactiveyesfontcolor: isactiveyesfontcolor,
                isactivenofontcolor: isactivenofontcolor,
                selectedFile: parsedresult.ReferenceFiles,
                ReferenceFiles: reference_files,
                curBinFiles: curBinFiles,
                purpose_chars: purpose_chars,
                association: association,
                gc_id: gc_id,
                sel_grouping: sel_grouping,

            });
        } else {
            //console.log("in add");
            //console.log("in add memberList==>"+JSON.stringify(memberList));
            //console.log("in add committeemembers==>"+JSON.stringify(committeemembers));
            this.setState({
                ready: true,
                selectedmember: selectedmember,
                committeelist: filtered_committees,
                memberList: memberList,
                committeeroles: committeeroles,
                committeemembers: committeemembers,
                thirdpartyembers: thirdpartyembers,
                selectedFile: selectedFile,

            });
        }
		this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }
    handleMemberChange = event => {

        event.preventDefault();
        //console.log("c==>"+event.target.name);
        var c = parseInt(event.target.name.split('-')[1])
        let committeemembers = JSON.parse(JSON.stringify(this.state.committeemembers));

        let index_val;
        committeemembers.forEach((value, index) => {

            //console.log(value.id,c,parseInt(event.target.value))

            if (value.id === c) {
                //console.log("handleMemberChange index==>"+index)

                //console.log("========",value.members)
                value.members.forEach((element, index1) => {
                    if (element.employeeid === parseInt(event.target.value)) {
                        index_val = index1;
                        element.selected = true
                        value.selectedemp = parseInt(event.target.value)
                    } else {
                        element.selected = false
                    }
                })
            }
        });
        //console.log("index_val==>"+index_val);
        //committeemembers[c].members.splice(index_val, 1);
        //console.log("committeemembers==>"+JSON.stringify(committeemembers));
        let membersList = [];
        committeemembers.forEach((value) => {
            if (value.id === c) {
            } else {
                value.members.forEach((element, index1) => {
                    if (!element.selected && index1 === index_val) {
                        value.members.splice(index_val, 1);
                    }
                });
                //membersList = value.members;

            }
        });

        this.setState({ committeemembers: committeemembers })

    }
    handleReportsToChange = event => {
        event.preventDefault();
        var c = parseInt(event.target.name.split('-')[1])
        let committeemembers = JSON.parse(JSON.stringify(this.state.committeemembers));

        committeemembers.forEach((value, index) => {
            if (value.id === c) {

                value.roles.forEach((element) => {

                    if (element.committeeroleid === parseInt(event.target.value)) {
                        element.selected = true
                        value.selectedrole = parseInt(event.target.value)
                    } else {
                        element.selected = false
                    }
                })
            }
        })

        this.setState({ committeemembers: committeemembers })

    }
    handleCommitteeMembersDeleteClick = (index) => (event) => {
        event.preventDefault()
        let tempcm = []
        tempcm = this.state.committeemembers;
        let membersList = this.state.memberList;
        console.log("index==>" + index);
        console.log("tempcm==>" + JSON.stringify(tempcm));
        console.log("membersList==>" + JSON.stringify(membersList));
        //console.log("membersList==>"+JSON.stringify(this.state.memberList));
        tempcm.splice(index, 1);

        let exists = {};
        tempcm.forEach((value) => {

            value.members.forEach((element, index1) => {
                exists[element.employeeid] = false;
                if (element.selected) {
                    exists[element.employeeid] = true;
                }
            });
        });
        var flags = {}, outputMembers = [];
        for (let i = 0; i < membersList.length; i++) {
            //console.log(membersList[i].employeeid+"<=exists==>"+exists[membersList[i].employeeid]);
            if (exists[membersList[i].employeeid]) continue;
            outputMembers.push(membersList[i]);
        }
        if (outputMembers.length === 0) {
            outputMembers = this.state.memberList;
        }
        let plusIconShow = true;
        if (outputMembers.length < membersList.length) { plusIconShow = true; }

        this.setState({ committeemembers: tempcm, committeeMembersPlusIconShow: plusIconShow })
    }
    handleCommitteeAddClick = event => {
        event.preventDefault()
        let tempcm = []
        tempcm = this.state.committeemembers
        //console.log("tempcm==>"+JSON.stringify(tempcm));
        //console.log("membersList==>"+JSON.stringify(this.state.memberList));

        let membersList = this.state.memberList;
        let selectedMembers = [];
        let exists = {};
        tempcm.forEach((value) => {

            value.members.forEach((element, index1) => {
                exists[element.employeeid] = false;
                if (element.selected) {
                    //value.members.splice(index1, 1);
                    exists[element.employeeid] = true;
                    //selectedMembers.push(element);
                }
            });
        });
        //console.log("exists==>"+JSON.stringify(exists));
        var flags = {}, outputMembers = [];
        for (let i = 0; i < membersList.length; i++) {
            //console.log(membersList[i].employeeid+"<=exists==>"+exists[membersList[i].employeeid]);
            if (exists[membersList[i].employeeid]) continue;
            //flags[selectedMembers[i].employeeid] = true;
            outputMembers.push(membersList[i]);
        }
        if (outputMembers.length === 0) {
            outputMembers = this.state.memberList;
        }
        //console.log("outputMembers==>"+JSON.stringify(outputMembers));
        tempcm.push({
            id: this.state.committeemembers.length + 1,
            //id: this.state.memberList.length + 1,
            //members: this.state.memberList,
            members: outputMembers,
            roles: this.state.committeeroles
        });
        let plusIconShow = true;
        //if(outputMembers.length === 1) {plusIconShow = false;}
        if (this.state.committeemembers.length === this.state.memberList.length) { plusIconShow = false; }

        this.setState({ committeemembers: tempcm, committeeMembersPlusIconShow: plusIconShow })
    }

    handleTPAddClick = event => {

        event.preventDefault()

        let tmparr = this.state.thirdpartyembers;
        let employeeid = Utils.genKey(10);
        tmparr.push({
            employeeid: employeeid,
            thirdpartyembername: "",
            thirdpartyembercompany: "",
            thirdpartyemberreason: ""
        })

        this.setState({ thirdpartyembers: tmparr })

    }

    handleTPAddNameChange = event => {
        event.preventDefault()
        var c = parseInt(event.target.name.split('-')[1])
        let tmparr = this.state.thirdpartyembers
        tmparr[c - 1].thirdpartyembername = event.target.value
        this.setState({ thirdpartyembers: tmparr })

    }
    handleTPAddCompanyChange = event => {
        event.preventDefault()
        var c = parseInt(event.target.name.split('-')[1])
        let tmparr = this.state.thirdpartyembers

        tmparr[c - 1].thirdpartyembercompany = event.target.value
        //console.log(tmparr)
        this.setState({ thirdpartyembers: tmparr })
        // console.log(c)
    }
    handleTPAddReasonChange = event => {
        event.preventDefault()
        var c = parseInt(event.target.name.split('-')[1])
        let tmparr = this.state.thirdpartyembers

        tmparr[c - 1].thirdpartyemberreason = event.target.value
        //console.log(tmparr)
        this.setState({ thirdpartyembers: tmparr })
        // console.log(c)
    }
    formatDate = (date) => {
        let day = date.getDate();
        let monthIndex = date.getMonth() + 1;
        let year = date.getFullYear();

        if (day < 10) {
            day = '0' + day;
        }
        if (monthIndex < 10) {
            monthIndex = '0' + monthIndex;
        }

        return day + '/' + monthIndex + '/' + year;
    }



    handleSubmitClick = (event) => {
        event.preventDefault();
        var committeeid = Store.getStoreData('modal_committee_Edit_CommitteeID') === null ? 0 : parseInt(Store.getStoreData('modal_committee_Edit_CommitteeID'));
        let committeename = this.state.committeename.trim();
        let perposeofcommitee = this.state.perposeofcommitee.trim();
        if (committeename === "" || perposeofcommitee === "") {
            if (committeename === "") {
                // alert("Please enter a committee name.");
                // return false;
                let message = 'Please enter a committee name.'
                let alert_param = {
                    title: 'Alert', message: message, ok_text: 'OK', confirm: false,
                    alertHandler: this.checkvalidation, stack: {}
                }

                this.setState({ alert_param: alert_param })
                return false;
            }
            if (perposeofcommitee === "") {
                // alert("Please enter the purpose of the committee.");
                // return false;
                let message = 'Please enter the purpose of the committee.'
                let alert_param = {
                    title: 'Alert', message: message, ok_text: 'OK', confirm: false,
                    alertHandler: this.checkvalidation, stack: {}
                }
                this.setState({ alert_param: alert_param })
                return false;
            }

        }

        //console.log("committeename",committeename);
        //console.log("perposeofcommitee",perposeofcommitee)

        let module_configs = Store.getStoreData('module_configs');
        let committeemembers = JSON.parse(JSON.stringify(this.state.committeemembers));
        let filtered_committees = Store.getStoreData('filtered_committees')
        let is_role_chair_exits = false;
        let tmparr = [];
        //console.log("committeemembers==>", committeemembers);
        committeemembers.forEach(function (value, i) {
            let o = {}
            value.members.forEach(function (element) {
                if (element.selected == true) {
                    o.employeeid = element.employeeid;
                    o.employeename = element.employeename;
                    o.assigneddate = new Date();
                }
            })
            value.roles.forEach(function (element) {
                if (element.selected == true) {
                    o.committeeroleid = element.committeeroleid;
                    o.committeerolename = element.commiitteerolename;
                }
            })
            if (Object.keys(o).length > 3) tmparr.push(o)
        });

        //console.log("tmparr==>", tmparr);
        tmparr.forEach(function (value, i) {
            let re = /(chair*)/i;
            let found = value.committeerolename.match(re);
            //console.log("ZZ=>",found);
            if (value.committeeroleid.toString() === 'HdlerPX27n3J' || value.committeeroleid.toString() === '1' || found !== null) { is_role_chair_exits = true; }
        });
        if (tmparr.length === 0) {
            //alert('You cannot create a committee with no members or members with no role.');
            let message = 'You cannot create a committee with no members or members with no role.'
            let alert_param = {
                title: 'Alert', message: message, ok_text: 'OK', confirm: false,
                alertHandler: this.checkvalidation, stack: {}
            }

            this.setState({ alert_param: alert_param })
            return false;

        }
        if (module_configs.general.chair_is_a_required_field && !is_role_chair_exits) {
            //alert('One member must be appointed to hold the role of Chair.');
            let message = 'Please appoint a Chair for this committee'
            let alert_param = {
                title: 'Alert', message: message, ok_text: 'OK', confirm: false,
                alertHandler: this.checkvalidation, stack: {}
            }

            this.setState({ alert_param: alert_param })
            return false;
            //return false;
        }
        if (committeeid === 0) {
            for (let committee of filtered_committees) {
                // if(committee)
                let json_data = committee.json_data;
                // console.log("name", json_data.committeename);
                if (json_data.committeename === committeename) {
                    //alert("This Committee Name already exists");
                    let message = 'This Committee name already exists'
                    let alert_param = {
                        title: 'Alert', message: message, ok_text: 'OK', confirm: false,
                        alertHandler: this.checkvalidation, stack: {}
                    }

                    this.setState({ alert_param: alert_param })
                    return false;
                }
            }
        }

        // console.log("this.state.reportsto", this.state.reportsto, filtered_committees )
        let level = 0;
        if (this.state.reportsto !== "") {
            for (let committee of filtered_committees) {
                if (parseInt(committee.id) === parseInt(this.state.reportsto)) {
                    let json_data = committee.json_data;
                    level = ('level' in json_data) ? parseInt(json_data.level) + 1 : 0;
                }
            }
        }

        let isActiveVal = (this.state.isactive) ? 1 : 0;
        let active_childrenCount = 0;

        if (parseInt(committeeid) > 0 && isActiveVal === 0) {
            for (let committee of filtered_committees) {
                if (parseInt(committee.reportsto) === parseInt(committeeid) && committee.IsActive) {
                    active_childrenCount = active_childrenCount + 1;
                }
            }
        }

        if (active_childrenCount > 0) {
            //alert("This committee has dependants and cannot be made inactive");
            let message = 'This committee has dependants and cannot be made inactive'
            let alert_param = {
                title: 'Alert', message: message, ok_text: 'OK', confirm: false,
                alertHandler: this.checkvalidation, stack: {}
            }

            this.setState({ alert_param: alert_param })
            return false;
        }


        this.setState({ ready: false });
        setTimeout(() => {
            let grouping_parts = this.state.sel_grouping === '0' ? ['0', '0'] : this.state.sel_grouping.split('-')

            let submitedvalue = {};
            let committeeData = (Store.getStoreData('committeeData') === null) ? {} : Store.getStoreData('committeeData');
            let committeeDataJson = parseInt(committeeid) === 0 ? {} : committeeData;

            committeeDataJson.committeename = this.state.committeename;
            committeeDataJson.reportsto = this.state.reportsto;
            committeeDataJson.level = level;
            committeeDataJson.isparentidcommittee = true;
            committeeDataJson.committeemembers = tmparr;
            committeeDataJson.thirdpartyembers = this.state.thirdpartyembers;
            committeeDataJson.perposeofcommitee = this.state.perposeofcommitee;
            committeeDataJson.meetingfrequency = this.state.meetingfrequency;
            committeeDataJson.ReferenceFiles = this.state.ReferenceFiles;

            if (committeeid === 0) {
                committeeDataJson.association = this.state.association;
                committeeDataJson.gc_id = this.state.gc_id;
                committeeDataJson.grouping_id = grouping_parts[0];
                committeeDataJson.grouping_value_id = grouping_parts[1];
            }




            submitedvalue["PK_CommitteeID"] = committeeid;
            submitedvalue["system_id"] = 1001;
            submitedvalue["IsActive"] = isActiveVal;


            submitedvalue['json_data'] = committeeDataJson;
            let postData = {};
            postData['command'] = committeeid === 0 ? 'addCommittee' : 'updateCommittee'
            postData['data'] = submitedvalue;
            let api = new APICall();
            api.command(postData, this.processaddCommittee);
        }, 30);



        // console.log("submitedvalue", postData)

    }

    processaddCommittee = (result) => {
		console.log("processaddCommittee result,",result);
		let committeeUtils = new CommitteeUtils();
        let message = "The committee has been added.";
		if(Store.getStoreData('modal_committee_Edit_CommitteeID') !== null){
			message = "The committee has been updated";
		}else{
			committeeUtils.addCommitteesToCommitteesWithCount(result.committee);
		}
		//let committee_with_count = Store.getStoreData('committee_with_count');
		//console.log("processaddCommittee result,",committee_with_count);
        let title = "Success";
		Store.updateStore('modal_committee_Edit_CommitteeID', null);

        let alert_param = { title: title, message: message, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {} }
        this.setState({ ready: true,alert_param: alert_param })
    }

    checkvalidation = (result, stack) => {
        //console.log(result,stack)
        this.setState({ alert_param: null })
    }

    alertremoveHandler = (result, stack) => {
        this.setState({ alert_param: null })
        Store.updateStore('OverlayHeight', null);
        Store.updateStore('modal_committee_Edit', false);
        Store.updateStore('modal_overlay_committee_Edit', null);
        Store.updateStore('modal_committee_Edit_CommitteeID', null);

        //this.refreshPage();
        this.props.passCommitteeTabRefreshInstruction({ 'committee_updated': true, refresh_time: new Date().getTime() });
        this.props.closeModal();
    }







    handleSubmitClick_obsuate = event => {
        event.preventDefault();

        let committeename = this.state.committeename.trim();
        let perposeofcommitee = this.state.perposeofcommitee.trim();
        if (committeename === "" || perposeofcommitee === "") {
            if (committeename === "") {
                //alert("Please enter a committee name.");
                let message = 'Please enter a committee name.'
                let alert_param = {
                    title: 'Alert', message: message, ok_text: 'OK', confirm: false,
                    alertHandler: this.handlesubmitcheck, stack: {}
                }

                this.setState({ alert_param: alert_param })
                return false;
            }
            if (perposeofcommitee === "") {
                //alert("Please enter the purpose of the committee.");
                let message = 'Please enter the purpose of the committee.'
                let alert_param = {
                    title: 'Alert', message: message, ok_text: 'OK', confirm: false,
                    alertHandler: this.handlesubmitcheck, stack: {}
                }

                this.setState({ alert_param: alert_param })
                return false;
            }

        }
        let module_configs = Store.getStoreData('module_configs');
        //console.log("module_configs==>",module_configs);
        //return;
        let committeemembers = JSON.parse(JSON.stringify(this.state.committeemembers));
        //console.log("committeemembers==>", committeemembers);

        let is_role_chair_exits = false;
        let tmparr = [];
        committeemembers.forEach(function (value, i) {
            let o = {}
            value.members.forEach(function (element) {
                if (element.selected == true) {
                    o.employeeid = element.employeeid;
                    o.employeename = element.employeename;
                    o.assigneddate = new Date();
                }
            })
            value.roles.forEach(function (element) {
                if (element.selected == true) {
                    o.committeeroleid = element.committeeroleid;
                    o.committeerolename = element.commiitteerolename;
                }
            })
            if (Object.keys(o).length > 3) tmparr.push(o)
        });

        console.log("tmparr==>", tmparr);
        tmparr.forEach(function (value, i) {
            if (value.committeeroleid.toString() === 'HdlerPX27n3J') { is_role_chair_exits = true; }
        });
        //console.log("is_role_chair_exits=>",is_role_chair_exits);
        //console.log("tmparr=>",tmparr);
        //if(this.state.reportsto === '') {
        //    alert('You must select a Committee to Report To')
        //    return false;
        //}


        if (tmparr.length === 0) {
            //alert('You cannot create a committee with no members or members with no role.');
            let message = 'You cannot create a committee with no members or members with no role.'
            let alert_param = {
                title: 'Alert', message: message, ok_text: 'OK', confirm: false,
                alertHandler: this.handlesubmitcheck, stack: {}
            }

            this.setState({ alert_param: alert_param })
            return false;
        }
        if (module_configs.general.chair_is_a_required_field && !is_role_chair_exits) {
            //alert('One member must be appointed to hold the role of Chair.');
            let message = 'One member must be appointed to hold the role of Chair..'
            let alert_param = {
                title: 'Alert', message: message, ok_text: 'OK', confirm: false,
                alertHandler: this.handlesubmitcheck, stack: {}
            }

            this.setState({ alert_param: alert_param })
            return false;
        }

        this.setState({ ready: false }, () => {
            let committee_count = 0;
            const url = Store.getStoreData('api_url');
            let postData = { command: "getCountByCommitteeName", data: {  committeename: committeename } };

            let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData) };
            fetch(url, httpVerb)
                .then((res) => res.json())
                .then(result => {
                    console.log("committeecount=>" + result);
                    //console.log("MM=>"+JSON.stringify(result.base64filedata.bin_file));
                    //that.downloadFileToBrowser(result.base64filedata.bin_file,result.base64filedata.name);
                    if (typeof result.commiteecount !== 'undefined') {
                        committee_count = result.commiteecount;
                    }
                    //if(result > 0){
                    //	alert("This Committee Name already exists");
                    //	return false;
                    //}else{


                    //console.log("tmparr==>"+JSON.stringify(tmparr));
                    var committeeid = Store.getStoreData('modal_committee_Edit_CommitteeID') == null ? 0 : parseInt(Store.getStoreData('modal_committee_Edit_CommitteeID'));

                    let level = 0;
                    if (this.state.reportsto !== "") {
                        let postData1 = { command: "getCommitteeByID", data: {  committeeid: this.state.reportsto } };
                        let httpVerb1 = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + JSON.stringify(postData1) };
                        fetch(url, httpVerb1)
                            .then((res) => res.json())
                            .then(result2 => {
                                console.log("getCommitteeByID=>" + result2);
                                let prevlevel = JSON.parse(result2.Json_Data).level;
                                /*if(prevlevel === 2){
                                    alert("This 'Report To' Committee is not eligible for selection");
                                    committeeid = -1;
                                    return false;
                                }else{
                                    level = prevlevel + 1;
                                }*/
                                level = prevlevel + 1;
                                this.submitData(committeeid, level, tmparr, committee_count);
                            })
                    } else {
                        this.submitData(committeeid, level, tmparr, committee_count);
                    }



                });
        });


        //return;

        //return;
        //this.closeModal();
        //this.refreshPage();

    }
    handlesubmitcheck = (result, stack) => {
        //console.log(result,stack)
        this.setState({ alert_param: null })
    }
    filesLoaded_old = (files) => {
        this.setState({ binFiles: files });

    }
    committeeMembersModalOpen = (jsonObj) => {

        this.setState({ committeemembers: jsonObj.committeemembers });
    }
    thirdPartyMembersModalOpen = (jsonObj) => {

        this.setState({ thirdpartyembers: jsonObj.thirdpartyembers });
    }
    processCommitteeMembersData = () => {

        let ret = { data: [], columns: [] };
        let i = 0;
		let employee_assoc = Store.getStoreData('employee_assoc');
        let check_role_flag = false;

        ret.columns = [{ Header: 'Name', accessor: 'employee_name', width: '40%', headerStyle: { textAlign: 'left' } },
        { Header: 'Role', accessor: 'role', width: '40%', headerStyle: { textAlign: 'left' } }
        ];
        //console.log("this.state.committeemembers=>",this.state.committeemembers);
        this.state.committeemembers.map((cm, index) => {
            let employee_name = '';
            let role_name = '';
			let ms=[];
            cm.members.forEach(function (item) {
				//let is_active = ('is_active' in cm) ? cm.is_active: true;
				//if(is_active){
					if (typeof cm.selectedemp !== 'undefined' && item.employeeid.toString() === cm.selectedemp.toString()) {
						employee_name = item.employeename;
					}
					//ms.push(item);
				//}
				//item.is_active=is_active;
				//ms.push(item);
            });
			//cm.members = ms;
            cm.roles.forEach(function (item) {
                if (typeof cm.selectedrole !== 'undefined' && item.committeeroleid.toString() === cm.selectedrole.toString()) {
                    role_name = item.commiitteerolename;
                    check_role_flag = true;
                }
            });
            if (typeof cm.selectedemp !== 'undefined') {
				if(employee_assoc[cm.selectedemp.toString()].is_active){
					let elem = {
						'index': index,
						'employee_id': cm.selectedemp,
						'employee_name': employee_name,
						'role': role_name,

					};
					ret.data.push(elem);
				}
            }
        });

        /*if (Store.getStoreData('modal_committee_Edit_CommitteeID') > 0 && this.state.committeemembers.length > 0 && !check_role_flag) {
            // alert("Please update roles of members for this committee.");
            let message = 'One or more mandatory roles must be allocated'
            let alert_param = {
                title: 'Alert', message: message, ok_text: 'OK', confirm: false,
                alertHandler: this.checkCommitteeMembersData, stack: {}
            }

            this.setState({ alert_param: alert_param })
            return false;
        }*/
console.log("ret,",ret);
        return ret;
    }
    checkCommitteeMembersData = (result, stack) => {
        //console.log(result,stack)
        this.setState({ alert_param: null })
    }
    processThirdPartyMembersData = () => {


        let ret = { data: [], columns: [] };
        let i = 0;

        ret.columns = [{ Header: 'Name', accessor: 'thirdpartyembername', width: '40%', headerStyle: { textAlign: 'left' } },
        { Header: 'Company', accessor: 'thirdpartyembercompany', width: '40%', headerStyle: { textAlign: 'left' } },
        { Header: 'Reason', accessor: 'thirdpartyemberreason', width: '20%', headerStyle: { textAlign: 'left' } }
        ];
        console.log("this.state.thirdpartyembers=>", this.state.thirdpartyembers);
        this.state.thirdpartyembers.map((cm, index) => {

            let elem = {
                'index': index,
                'employeeid': cm.employeeid,
                'thirdpartyembername': cm.thirdpartyembername,
                'thirdpartyembercompany': cm.thirdpartyembercompany,
                'thirdpartyemberreason': cm.thirdpartyemberreason,

            };
            ret.data.push(elem);
        });


        return ret;
    }

    changeCommitteeAssociation = (assoc) => {
        this.setState({ association: assoc })
    }

    optionCompare = (a, b) => {
        return a.option.localeCompare(b.option)
    }
    nameCompare = (a, b) => {
        return a.name.localeCompare(b.name)
    }


    render() {
        console.log("this.state=>", this.state);

        let committeelist = this.state.committeelist;
        for (let cl of committeelist) {
            cl.included = false
        }
        let companies = Store.getStoreData('companies_assoc')
        let tag_values = Store.getStoreData('tag_values')
        let company_name = Store.getStoreData('company_name')
        let option_companies = []
        let option_groupings = []
        for (let c_id in companies) {
            if(companies[c_id].is_active === 1)option_companies.push({ id: c_id, option: companies[c_id].company_name })
        }
        for (let g_id in tag_values) {
            for (let gv_id in tag_values[g_id].values) {
                option_groupings.push({ id: `${g_id}-${gv_id}`, option: `${tag_values[g_id].name} - ${tag_values[g_id].values[gv_id]}` })
            }
        }
        option_companies.sort(this.optionCompare);
        option_groupings.sort(this.optionCompare);
        console.log('this.state.committeelist', this.state.committeelist)
        let option_count = 0;
        let association_disabled = ('undefined' != typeof Store.getStoreData('modal_committee_Edit_CommitteeID') && parseInt(Store.getStoreData('modal_committee_Edit_CommitteeID')) > 0) ? true : false;
        return (
            <MRModalContainer>

                <MRModalHeader>
                    <MRModalHeaderText>Add Committee</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{ clear: "both" }}></div>
                    <MRModalSubHeaderText></MRModalSubHeaderText>
                    <div style={{ clear: "both" }} ref={el => { this.el = el; }}></div>
                </MRModalHeader>
                <MRModalBody>
                    <div style={{ padding: "15px", height: "650px", overflowY: "scroll", backgroundColor: "#F1F6F7" }}>
                        {
                            (() => {
                                if(!this.state.ready) {
                                    return <div><CSLLoaderNonPromise /></div>
                                }

                            })()
                        }
                        <form>
                            <p style={{ marginBottom: "17px" }}><b>Committee Name</b></p>
                            <ReactFormGroup>
                                <input style={{ fontFamily: "Montserrat, sans-serif" }} className="react-form-control" name="committeename" id="committeename" value={this.state.committeename} onChange={this.handleInputChange} placeholder="Committee Name"></input>
                            </ReactFormGroup>
							{
								(() => {
									if(Store.getStoreData('parent_has_group_companies')){
										return (
											<div>
												<p style={{ marginBottom: "7px" }}><b>Associate Committee With</b></p>
												<ReactFormGroup>
													<input type="checkbox" name="association" checked={this.state.association === 'parent' ? true : false} onChange={() => this.changeCommitteeAssociation('parent')} disabled={association_disabled} />
													&nbsp;&nbsp;The Parent Company - {company_name}
												</ReactFormGroup>
												<ReactFormGroup>
													<input type="checkbox" name="association" checked={this.state.association === 'gc' ? true : false} onChange={() => this.changeCommitteeAssociation('gc')} disabled={association_disabled} />
													&nbsp;&nbsp;A Group Company
												</ReactFormGroup>
												{
													(() => {
														if (this.state.association === 'gc') {
															return (<ReactFormGroup>
																<select className="react-form-control" name="gc_id" onChange={this.handleInputChange} value={this.state.gc_id} disabled={association_disabled} style={{ float: 'right', width: 'calc(100% - 20px)' }}>

																	<option value="none">Select group company...</option>
																	{
																		option_companies.map((item, key) => {
																			return <option key={key} value={item.id}>{item.option}</option>
																		})
																	}
																</select>
																<div style={{ clear: 'both' }} />
															</ReactFormGroup>
															)
														}
													})()
												}

												{
													(() => {
														if (this.state.association === 'grouping') {
															return (<ReactFormGroup>
																<select className="react-form-control" name="sel_grouping" onChange={this.handleInputChange} value={this.state.sel_grouping} disabled={association_disabled} style={{ float: 'right', width: 'calc(100% - 20px)' }}>

																	<option value="0">Select a group of companies...</option>
																	{
																		option_groupings.map((item, key) => {
																			return <option key={key} value={item.id}>{item.option}</option>
																		})
																	}
																</select>
																<div style={{ clear: 'both' }} />
															</ReactFormGroup>
															)
														}
													})()
												}

											</div>
										);
									}
								})()
							}

                            <p style={{ marginBottom: "17px" }}><b>Reports To</b></p>
                            <ReactFormGroup>
                                {/* <select className="react-form-control" name="reportsto" onChange={this.handleInputChange}>
                                <option value="Reports To">Reports To</option>
                            </select> */}

                                <select style={{ width: "102%" }} className="react-form-control" name="reportsto" onChange={this.handleInputChange} value={this.state.reportsto}>
                                    <option value="">Reports To...</option>
                                    {
                                        committeelist.map(committee => {
                                            let association = 'association' in committee.json_data ? committee.json_data.association : 'parent';
                                            switch (this.state.association) {
                                                case 'gc': if (committee.is_member_of_this_committee && association === 'gc' && committee.json_data.gc_id === this.state.gc_id) {
                                                    option_count++
                                                    committee.included = true;
                                                    return (
                                                        <option key={committee.PK_CommitteeID} value={committee.PK_CommitteeID}>{committee.display}</option>
                                                    )
                                                }
                                                    break;
                                                case 'grouping': if (committee.is_member_of_this_committee && association === 'grouping' && `${committee.json_data.grouping_id}-${committee.json_data.grouping_value_id}` === this.state.sel_grouping) {
                                                    option_count++
                                                    committee.included = true;
                                                    return (
                                                        <option key={committee.PK_CommitteeID} value={committee.PK_CommitteeID}>{committee.display}</option>
                                                    )
                                                }
                                                    break;
                                                default: if (committee.is_member_of_this_committee && association === 'parent') {
                                                    option_count++
                                                    committee.included = true;
                                                    return (
                                                        <option key={committee.PK_CommitteeID} value={committee.PK_CommitteeID}>{committee.display}</option>
                                                    )
                                                }
                                            }
                                        })
                                    }
                                    {
                                        (() => {
                                            if (option_count > 0 && option_count < committeelist.length) {
                                                return (<option disabled>_______________________</option>)
                                            }
                                        })()
                                    }
                                    {
                                        committeelist.map(committee => {
                                            if (!committee.included) {
                                                return (
                                                    <option key={committee.PK_CommitteeID} value={committee.PK_CommitteeID}>{committee.display}</option>
                                                )
                                            }
                                        })
                                    }
                                </select>

                            </ReactFormGroup>
                            <hr style={{ border: "1px solid #dbdbdb" }}></hr>
                            <p style={{ marginBottom: "17px" }}><b>Committee Members</b></p>

                            <div>
                                <AddCommitteeMembers
                                    committeeMembersModalOpen={this.committeeMembersModalOpen}
                                    committeemembers={this.state.committeemembers}
                                    memberList={this.state.memberList}
                                    association={this.state.association}
                                    gc_id={this.state.gc_id}
                                    sel_grouping={this.state.sel_grouping}
                                    committeeroles={this.state.committeeroles}
                                    showAddIcon={true}
                                    headerText={'Committee Members'} />
                                <OverviewInfoBody>
                                    <CSLTable add={false} showPagination={false} processData={this.processCommitteeMembersData} refreshCallback={this.refreshCallback} tableRows={1000} />
                                </OverviewInfoBody>
                            </div>
                            <hr style={{ border: "1px solid #dbdbdb", marginTop: "10px" }}></hr>
                            <p style={{ marginBottom: "17px" }}><b>Third Party Members</b></p>

                            <div>
                                <AddThirdPartyMembers showAddIcon={true} thirdPartyMembersModalOpen={this.thirdPartyMembersModalOpen} thirdpartyembers={this.state.thirdpartyembers} headerText={'Third Party Members'} />
                                <OverviewInfoBody>
                                    <CSLTable add={false} showPagination={false} processData={this.processThirdPartyMembersData} tableRows={1000} />
                                </OverviewInfoBody>
                            </div>
                            <hr style={{ border: "1px solid #dbdbdb", marginTop: "10px" }}></hr>
                            <div>
                                <div>
                                    <div style={{ marginBottom: "7px", float: "left" }}><b>Committee Purpose</b></div>
                                    <div style={{ marginBottom: "7px", float: "right" }}><b>No. of Characters Allowed :</b> {this.state.purpose_chars}</div>
                                </div>
                                <ReactFormGroup>
                                    <textarea className="react-form-control" id="perposeofcommittee" name="perposeofcommitee" value={this.state.perposeofcommitee} onChange={this.handleInputChange} placeholder="Purpose of Committee" style={{ height: "150px", fontFamily: "Montserrat, sans-serif" }} maxlength="250"></textarea>
                                </ReactFormGroup>
                            </div>
                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            {/*<UploadReferenceFileButton onClick={this.handleFileSelect}>Upload Terms of Reference </UploadReferenceFileButton>
						<input type="file" id="file" ref={this.inputref} style={{display: "none"}} onChange={this.handleselectedFile.bind(this)} encType="multipart/form-data"/>
                        <UploadReferenceFile>
							{this.state.fileRows}
                        </UploadReferenceFile>*/}
                            <p style={{ marginBottom: "7px" }}><b>Upload Terms of Reference</b></p>
                            <div style={{ width: "50%" }}>
                                <div style={{ padding: "10px" }}>
                                    <Style.ModalNameInputDiv >
                                        {
                                            (() => {
                                                return <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.curBinFiles} height={"200px"} onRemoveFile={this.filesRemoved} showFileButton={true} handleDownload={this.downloadAttachment} removable={true}/>
                                            })()
                                        }
                                    </Style.ModalNameInputDiv>
                                </div>
                            </div>
                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <p style={{ paddingTop: "10px", marginBottom: "17px" }}><b>Meeting Frequency</b></p>

                            {
                                (() => {
                                    return (<ReactFormGroup>
                                        <select className="react-form-control" name="meetingfrequency" onChange={this.handleInputChange} value={this.state.meetingfrequency} style={{ width: 'calc(100% + 2%)' }}>

                                            <option value="">Select frequency</option>
                                            <option value="1">Annual</option>
                                            <option value="2">Semi-Annual</option>
                                            <option value="4">Quarterly</option>
                                            <option value="6">Bi-Monthly</option>
                                            <option value="12">Monthly</option>
                                            <option value="52">Weekly</option>
                                            <option value="104">Bi-weekly</option>
                                            <option value="0">Ad-hoc</option>

                                        </select>
                                        <div style={{ clear: 'both' }} />
                                    </ReactFormGroup>
                                    )
                                })()
                            }

                            {this.state.editMode && (
                                <div style={{ width: "100%", margin: "16px 0" }}>
                                    <p style={{ paddingTop: "10px", marginBottom: "17px" }}><b>Active ?</b></p>
                                    <IsActiveYesButton style={{ backgroundColor: this.state.isactiveyesbgcolor, color: this.state.isactiveyesfontcolor }} onClick={this.toggleIsActiveYesButton}>Yes</IsActiveYesButton> &nbsp;
                                    <IsActiveNoButton style={{ backgroundColor: this.state.isactivenobgcolor, color: this.state.isactivenofontcolor }} onClick={this.toggleIsActiveNoButton}>No</IsActiveNoButton>
                                </div>
                            )}
                            <hr style={{ border: "1px solid #dbdbdb", width: "200px" }}></hr>

                        </form>
                    </div>
                </MRModalBody>
                <MRModalFooter>
                    <div style={{ textAlign: "right", marginTop: "20px" }}>
                        <FormSubmitButton id="button_submitcommitteemodal" onClick={this.handleSubmitClick} style={{ float: "right" }}>Submit</FormSubmitButton>
                        <FormCancelButton id="button_cancelcommitteemodal" onClick={this.closeModal}>Cancel</FormCancelButton>
                        {/*<FormCancelButton onClick={this.openConfirmationBoxModal}>Cancel</FormCancelButton>*/}
                    </div>
                </MRModalFooter>
                <AlertBox alertParam={this.state.alert_param} />
            </MRModalContainer>
        );
    }
}
export default NewCommitteeModal;
