import React from 'react';
import styled from 'styled-components';
import WelcomeDateTime from './WelcomeDateTime';
import * as Style from './StyledComponents'

class WelcomeBanner extends React.Component
{
    render()
    {
        return (
            <Style.BannerContainer>
                <Style.Banner>
                    <Style.WelcomeText>{ this.props.welcome }</Style.WelcomeText>
                    <WelcomeDateTime />
                    <div style={{clear: "both"}}></div>
                </Style.Banner>
            </Style.BannerContainer>
        );
    }
}

export default WelcomeBanner;