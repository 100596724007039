import React from 'react';
import styled from 'styled-components';
import { FaTimes,FaPlusCircle , FaPen ,FaTrash} from 'react-icons/fa';
import CSLTable from '../Common/CSLTable';
import Utils from '../../Common/Utils.js';
import CommitteeRoleConfigModal from './CommitteeRoleConfigModal';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";

const EmailContainer = styled.div`
	width: 90%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const EmailLabel = styled.div`
	color: #595959;
	margin-top: 5px;
`;
const ConfigBtn = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    font-size: 13px;
    border-radius: 4px;
    padding: 5px 20px;
    cursor: pointer;
`;

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;

class CommitteeRoles extends React.Component
{
	state = {committeeroles: [], variables: null, show_role_config_modal: null, content: null, general: true, show_inactive: false};

	componentDidMount()
	{
		console.log("here 1=>",this.props.committeeroles);
		this.setState({committeeroles: this.props.committeeroles});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
            console.log("here 2=>",this.props.committeeroles);
			this.setState({committeeroles: this.props.committeeroles});
		}
	}

	showCommitteeRoleConfigModal = (committee_role_id) => (event) => {
		event.preventDefault();
		console.log("committee_role_id:", committee_role_id);
		let content = {};
		if(committee_role_id.toString() !== '0'){
			content = this.state.committeeroles.find((item) => {
				return item.committeeroleid === committee_role_id;
			});		
		}else{
			var unique_role_id = Utils.genKey(12);
			content.committeeroleid = unique_role_id;
			content.commiitteerolename = '';
			content.status = 'Active';
		}

		this.setState({content, show_role_config_modal: true});
	}

	modifyCommitteeRole = (content) => {
		console.log("content:", content);
		let {committeeroles} = this.state;
		let newcommitteeroles = [];
		let matched = 0;
		committeeroles.forEach((item) => {
			if (item.committeeroleid === content.committeeroleid) {
				item = content;
				console.log("matched", item);
				matched = 1;
			}
			newcommitteeroles.push(item);
		})
        if(matched === 0){newcommitteeroles.push(content);}
		console.log("newcommitteeroles:", newcommitteeroles);
		
		this.setState({committeeroles: newcommitteeroles, show_role_config_modal: null},()=>{
			this.props.updateCommitteeRoles(newcommitteeroles);
		});
	}

	closeModal = () => {
		this.setState({show_role_config_modal: null, content: null});
	}

	filter_table_data  = (data) => {
		let result = [];
		if(this.state.show_inactive){
		  for(let d of data) {
		    if(d.status.toUpperCase() === "INACTIVE"){
		      result.push(d);
		    }
		  }
		}else{
		  for(let d of data) {
		  	if(d.status.toUpperCase() === "ACTIVE"){
		  	  result.push(d);
		  	}
		    // result.push(d)
		  }
		}
		return result;
		// console.log("data ===>", data)
	}

	tableData = () => {
		let tabledata = this.state.committeeroles;
		tabledata = this.filter_table_data(tabledata);
		console.log("tabledata",tabledata);
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Role Type', accessor: 'commiitteerolename', minWidth: 120, headerStyle: {textAlign: 'left'}}, 
						{Header: 'Status', accessor: 'status', minWidth: 120, headerStyle: {textAlign: 'left'}},

                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <ConfigBtn onClick={this.showCommitteeRoleConfigModal(row.original.committeeroleid)}>Configure</ConfigBtn>
                           </div>
                        ), headerStyle:{textAlign: 'left'}}];
        tabledata.forEach((item) => {
			let status = (typeof item.status !== 'undefined') ? item.status : 'Active' ;
        	let elem = {committeeroleid: item.committeeroleid, commiitteerolename: item.commiitteerolename, status: status };
        	ret.data.push(elem);
        })
        return ret;
	}

	handlenaSectionIncluded = (section_id) => (event) => {
		console.log("content:", section_id);
		let {email} = this.state;
		let newemail = [];
		email.forEach((item) => {
			if (item.id === section_id) {
				item.enabled = item.enabled ? false : true; 
				console.log("matched", item);
			}
			newemail.push(item);
		})
		console.log("emails:", newemail);
		this.props.updateEmail(newemail);
	}

	ChangeInactive = (event) => {
		// event.preventDefault();
		let show_inactive = this.state.show_inactive ? false : true;
		this.setState({show_inactive});
	}



	render()
	{

		return (
			<div>

			{
				(() => {
					if (this.state.show_role_config_modal !== null) {
						return (<CommitteeRoleConfigModal variables={this.state.variables} content={JSON.parse(JSON.stringify(this.state.content))} modifyCommitteeRole={this.modifyCommitteeRole} closeModal={this.closeModal
						} />);
					}
				})()
			}
            
			
			<div styled={{clear:"both"}}></div>
				<EmailContainer>
					<EmailInnerContainer>
						<div style={{ float: "right", color: "#04ADA8", fontSize: "17px", cursor: "pointer"}} title="Add New Roletype" onClick={this.showCommitteeRoleConfigModal(0)}><FaPlusCircle /></div>
						<EmailBoldLabel style={{display: "inline-block"}}>Committee Role Type</EmailBoldLabel>
						<div styled={{clear:"both"}}></div>
						<EmailBoldLabel style={{padding: "10px", fontWeight: "600"}}>Show Inactive  <input type="checkbox" checked={this.state.show_inactive} onChange={this.ChangeInactive} /></EmailBoldLabel>
						<CSLTable add={false} processData={this.tableData} headerText={''} tableRows="10" refreshCallback={this.refreshState} />
					</EmailInnerContainer>
				</EmailContainer>			
			</div>
		);
	}
}

export default CommitteeRoles;