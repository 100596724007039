import React from 'react';
import styled from 'styled-components';
import Store from '../../../Common/Store.js';

const Dropdown = styled.select`
	display: block;
    box-sizing: border-box;
    height: 38px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
`;

class SingleSelect extends React.Component
{
	state = {data: null, selected: "Choose"};

	setComponentState = () => {
		// console.log("Select data:", this.props.data);
		let init = Store.getStoreData('init_filters')
		if(init) {
			this.setState({data: this.props.data, selected: 'Choose'});
		} else {
			this.setState({data: this.props.data});
		}
		
	}
	componentDidMount()
	{
		this.setComponentState()
	}

	componentDidUpdate(prevprops) {
		if(this.props !== prevprops) {
			this.setComponentState()
		}
	}

	handleChange = (event) => {
		// this.props.gatherSelected(event.target.value, this.state.data.type, this.state.data.accessor);
		this.props.selectCallback(event.target.value, this.props.stack)
		this.setState({selected: event.target.value});
	}

	render()
	{
		if (this.state.data === null) {
			return (<div>Loading...</div>);
		}
		return (
			<div style={{marginTop: "8px"}}>
				<Dropdown value={this.state.selected} onChange={this.handleChange}>
					<option value="Choose">Choose</option>
					{
						Object.keys(this.props.items).map((id) => {
							return (<option key={this.props.items[id].name} value={this.props.items[id].name}>{this.props.items[id].name}</option>);
						})
					}
				</Dropdown>
			</div>
		);
	}
}

export default SingleSelect;