import React from 'react';
import APICall from '../../Common/APICall';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
// import ClipLoader from 'react-spinners/ClipLoader';

const ModalBody = styled.div`
	width: 70%;
	position: absolute;
	background-color: #f7f7f7;
	box-shadow: 0 0 20px #a7a6a6;
	z-index: 1;
	padding: 20px;
	border: 1px solid #113253;
	border-radius: 5px;
	bottom: 1rem;
	height: 17vh;
`;
const EmailInput = styled.input`
	& {
		height: 30px;
		width: 98%;
		border-radius: 3px;
		box-shadow: 0 0 4px #c4c4c4;
		padding: 5px;
		color: #222222;
		font-size: 15px;
		font-family: 'Montserrat', sans-serif;
		border: 1px solid #ffffff;
	}
	&:focus {
		outline: none;
	}
`;
const SendTestMailButton = styled.button`
	background-color: #37ada7;
	border: 1px solid #37ada7;
	border-radius: 4px;
	padding: 10px 30px;
	cursor: pointer;
	color: #ffffff;
`;
const DummyOverlay = styled.div`
	display: table;
	box-sizing: border-box;
	height: calc(100vh - 65px);
	width: calc(100vw - 300px);
	background-color: #ffffff;
	position: fixed;
	left: 300px;
	top: 65px;
	z-index: 1000;
	opacity: 0.6;
`;

class TestEmailModal extends React.Component {
	state = { content: null, receiverEmail: '', isValidMail: false, alert_param: null, show_spinner: false };

	componentDidMount() {
		this.setState({ content: this.props.content });
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.setState({ content: this.props.content });
		}
	}
	ValidateEmail = (e) => {
		const typedValue = e.target.value;
		let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!typedValue || typedValue.length === 0 || !regEmail.test(typedValue)) {
			this.setState({ ...this.state, isValidMail: false, receiverEmail: e.target.value });
			return;
		}
		this.setState({ ...this.state, receiverEmail: e.target.value, isValidMail: true });
	};

	SendTestEmailModal = async (event) => {
		let message = '';
		event.preventDefault();
		if (this.state.isValidMail) {
			this.setState({ ...this.state, show_spinner: true });

			console.log('jsondata==>', this.state.content.body);
			let postData = {
				command: 'send_test_email_to_user',
				subject: this.state.content.subject,
				body: this.state.content.body,
				email: this.state.receiverEmail
			};

			let api = new APICall();
			api.command(postData, await this.afterSendCompleted);
		} else {
			//alert('');
			message = 'Specify valid email';
			this.props.closeTestModal(message, true);
		}
	};

	afterSendCompleted = (result) => {
		let message = '';
		//debugger;
		this.setState({ ...this.state, show_spinner: false });

		if (result.error_code == 0) {
			if (
				this.state.receiverEmail.includes('@outlook.com') ||
				this.state.receiverEmail.includes('@hotmail.com')
			) {
				message = `Mail has been sent successfully! <br/><br/>
				 Please note that e-mails to personal addresses (e.g. @hotmail.com, @outlook.com) may take significantly longer. If you do not receive an e-mail, please use an alternative address`;
			} else {
				message = 'Mail sent successfully!';
			}
			this.props.closeTestModal(message, false);
		} else {
			message = 'Problem while sending mail';
			this.props.closeTestModal(message, true);
		}
	};

	render() {
		if (this.state.content === null) {
			return <div>Loading...</div>;
		}

		return (
			<div>
				{(() => {
					if (this.state.show_spinner === true) {
						return (
							<div>
								<DummyOverlay>
									<div
										style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}
									>
										 {/*<ClipLoader size={150} color={'#123abc'} loading={true} />*/}
									</div>
								</DummyOverlay>
							</div>
						);
					}
				})()}
				<ModalBody>
					<div style={{ float: 'right' }}>
						<FaTimes style={{ cursor: 'pointer' }} onClick={this.props.closeModalOnXSign} />
					</div>
					<div style={{ marginTop: '20px' }}>
						<div
							style={{
								float: 'left',
								fontWeight: '600',
								color: '#222222',
								fontSize: '15px',
								paddingTop: '8px',
								width: '30%'
							}}
						>
							Enter Email Address
						</div>
						<div style={{ float: 'right', width: '70%', paddingRight: '20px', boxSizing: 'border-box' }}>
							<EmailInput name="subject" autoFocus onChange={(e) => this.ValidateEmail(e)} />
						</div>
					</div>
					<div style={{ clear: 'both' }} />
					<div style={{width: "100%", height: "2px", backgroundColor: "#EBEBEB", marginTop: "20px"}}></div>
					<div style={{ marginTop: '12px', display: 'flex', justifyContent: 'flex-end' }}>
						<SendTestMailButton onClick={this.SendTestEmailModal}>Send</SendTestMailButton>
					</div>
				</ModalBody>
			</div>
		);
	}
}

export default TestEmailModal;
