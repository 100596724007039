class ComDecom {
	compressObject(in_json, id_field) {
		let out_json = {fields: [], rows:[], id_field: id_field, id_pos: 0}
		let count = 0

		for(let id in in_json) {
			let row = []

			if(count === 0) {
				if(!(id_field in in_json[id]))out_json.fields.push(id_field)
				let field_count = 0
				for(let f in in_json[id]) {
					out_json.fields.push(f)
					if(f === id_field)out_json.id_pos = field_count
					field_count++
				}

				count++
			}
			if(!(id_field in in_json[id]))row.push(id)
			for(let f in in_json[id]) {
				if(f === id_field)row.push(in_json[id][f].toString())
				else row.push(in_json[id][f])
			}
			out_json.rows.push(row)
		}
		return out_json
	}

	compress(in_json, is_object=false, id_field='id') {
		let out_json = {'fields': [], rows: []}
		if(is_object) {
			out_json = this.compressObject(in_json, id_field)
			return out_json
		}
		let count = 0 
		for(let r of in_json) {
			let row = []
			if(count === 0) {
				for(let f in r) {
					out_json.fields.push(f)
				}
				count++
			}
			
			for(let f in r) {
				if(f === id_field)row.push(r[f].toString())
				else row.push(r[f])
			}
			out_json.rows.push(row)
		}
		
		return out_json
	}

	deCompressObject(in_json, id_field) {
		let out_json = {}
		for(let r of in_json.rows) {
			out_json[r[in_json.id_pos]] = {} 
			let field_count = 0
			for(let rf of in_json.fields) {
				out_json[r[in_json.id_pos]][rf] = r[field_count]
				field_count++
			}
		}
		return out_json
	}

	deCompress(in_json, is_object=false, id_field='id') {
		if(is_object) {
			let out_json = this.deCompressObject(in_json, id_field)
			return out_json
		}
		let out_json = []
		for(let r of in_json.rows) {
			let row = {}
			let field_count = 0
			for(let f of in_json.fields) {
				row[f] = r[field_count]
				field_count++
			}
			out_json.push(row)
		}

		return out_json
	}
}

export default ComDecom;