import React from 'react';
import {FaTimes, FaTrashAlt, FaQuestionCircle} from 'react-icons/fa';
import styled from 'styled-components';

const EmailContainer = styled.div`
	width: 70%;
	position: absolute;
	background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
	z-index: 1;
	padding: 20px;
`;
const EmailInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const EmailTextArea = styled.textarea`
    height: 150px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-family: 'Montserrat', sans-serif;
`;
const VariablePill = styled.div`
	padding: 10px 15px;
	background-color: #ffffff;
	color: #222222;
	font-weight: 600;
	border: 1px solid #222222;
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
	margin-top: 10px;
	border-radius: 2em;
	cursor: pointer;
`;
const CancelBtn = styled.button`
	background-color: #ffffff;
	border: 1px solid #EBEBEB;
	border-radius: 4px;
	padding: 10px 20px;
	cursor: pointer;
`;
const SubmitBtn = styled.button`
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 4px;
	padding: 10px 30px;
	cursor: pointer;
	color: #ffffff;
`;
const RiskSelect = styled.select`
    margin-top: 15px;
    display: inline-block;
    box-sizing: border-box;
    height: 35px;
    width: 86%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    margin-left: 14px;
	font-weight: bold;
`;


class CommitteeRoleConfigModal extends React.Component
{
	state = {content: null};

	componentDidMount()
	{
		this.setState({content: this.props.content , IsRoleNameProvided :false});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({content: this.props.content});
		}
	}

	closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
	}

	handleChange = (event) => {
		event.preventDefault();
		let {content} = this.state;
		content[event.target.name] = event.target.value;
		this.setState({content});
	}



	modifyCommitteeRole = (event) => {
		event.preventDefault();
        let {content} = this.state;
		if(content.commiitteerolename.trim() === ''){
			this.setState({IsRoleNameProvided : true});
		}else{
			this.props.modifyCommitteeRole(content);
		}
		
	}



	render()
	{
		if (this.state.content === null) {
			return (<div>Loading...</div>);
		}

		return (
			<EmailContainer>
				{
					(() => {
						if(this.state.content.commiitteerolename !== ''){
							return(
								<div style={{float: "left"}}>Configure Committee Role Type - {this.state.content.commiitteerolename}</div>
								)
						}else{
							return(
								<div style={{float: "left"}}>Add new committee Role Type</div>
								)
						}  
					})()
				}

				<div style={{float: "right"}}><FaTimes style={{cursor: "pointer"}} onClick={this.closeModal} /></div>
				<div style={{clear: "both"}}></div>
				<div style={{marginTop: "20px"}}>
					<div style={{float: "left", width: "10%", fontWeight: "600", color: "#222222", fontSize: "15px", paddingTop: "8px"}}>Role Type</div>
					<div style={{float: "left", width: "90%", paddingRight: "20px", boxSizing: "border-box"}}>
						<EmailInput name="commiitteerolename" value={this.state.content.commiitteerolename} onChange={this.handleChange}  />
					</div>
                        {
                            (() => {
                                if(this.state.IsRoleNameProvided){
                                    return(
                                        <div style={{marginLeft: "90px", marginTop: "5px"}}>
                                            <div style={{clear: "both"}}></div>
                                            <label style={{color:"#FF0000"}}>Please provide role name.</label>
                                        </div>
                                        )
                                }  
                            })()
                        }
					<div style={{clear: "both"}}></div>
				</div>
				<div style={{marginTop: "20px"}}>
					<div style={{float: "left", width: "10%", fontWeight: "600", color: "#222222", fontSize: "15px", paddingTop: "8px"}}>Status</div>
                        <RiskSelect style={{marginLeft:"48px", width: "29%"}} onChange={this.handleChange} name="status" value={this.state.content.status}>
                            <option key="Active" value="Active">Active</option>
                            <option key="Inactive" value="Inactive">Inactive</option>
                        </RiskSelect>
					<div style={{clear: "both"}}></div>
				</div>
				<div style={{width: "100%", height: "2px", backgroundColor: "#EBEBEB", marginTop: "20px"}}></div>

				<div style={{width: "100%", height: "2px", backgroundColor: "#EBEBEB", marginTop: "20px"}}></div>
				<div style={{textAlign: "right", marginTop: "20px"}}>
					<CancelBtn onClick={this.closeModal}>Cancel</CancelBtn>
					<SubmitBtn style={{marginLeft: "15px"}} onClick={this.modifyCommitteeRole}>Submit</SubmitBtn>
				</div>
			</EmailContainer>
		);
	}
}

export default CommitteeRoleConfigModal;